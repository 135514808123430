import React from 'react';

const MoreInfo = () => {
    return (
        <section className="py-12 bg-white text-dark-gray">
            <div className="max-w-7xl mx-auto px-8 text-center">
                <h2 className="text-4xl font-bold mb-8">Dowiedz się więcej o Quizonatorze</h2>
                <p className="text-lg mb-6">
                    Chcesz dowiedzieć się więcej o tym, jak działa nasze rozszerzenie? Odwiedź stronę dokumentacji, aby uzyskać szczegółowe informacje i instrukcje.
                </p>
                <a
                    href="https://docs.quizonator.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-block py-3 px-6 bg-light-green text-white font-bold rounded-full shadow hover:bg-light-green/90 transition duration-200"
                >
                    Przejdź do dokumentacji
                </a>
            </div>
        </section>
    );
}

export default MoreInfo;