import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="min-h-screen bg-gradient-to-r from-green-400 to-blue-500 text-white p-8">
            <div className="container text-left mx-auto bg-white bg-opacity-10 backdrop-blur-md rounded-lg p-6">
                <h1 className="text-4xl font-bold mb-6">Polityka Prywatności dla rozszerzenia "Quizonator"</h1>
                <p className="mb-6 text-lg">Data ostatniej aktualizacji: 07.10.2024</p>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">1. Informacje, które zbieramy</h2>
                    <h3 className="text-xl font-semibold mb-2">1.1. Dane dotyczące użytkowania</h3>
                    <p className="mb-4">
                        Rozszerzenie "Quizonator" zbiera następujące dane dotyczące użytkowania:
                    </p>
                    <ul className="list-disc list-inside mb-4 ml-4">
                        <li>
                            <span className="font-bold">Treść witryny</span>: Rozszerzenie analizuje treść stron, na których jest uruchomione, w celu pobierania pytań i odpowiedzi z quizów. Dane te są przetwarzane lokalnie na urządzeniu użytkownika i nie są przesyłane ani przechowywane przez nas na zewnętrznych serwerach.
                        </li>
                        <li>
                            <span className="font-bold">Wyniki quizów</span>: Wyniki quizów mogą być zbierane wyłącznie w celach statystycznych i analitycznych. Zebrane dane są anonimowe i nie są udostępniane osobom trzecim.
                        </li>
                    </ul>
                    <h3 className="text-xl font-semibold mb-2">1.2. Dane uwierzytelniające</h3>
                    <p>
                        Użytkownicy logują się do rozszerzenia "Quizonator" za pomocą adresu e-mail i hasła. Dane te są przechowywane bezpiecznie i używane wyłącznie do celów logowania oraz uwierzytelniania użytkowników. Nie są one udostępniane osobom trzecim.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">2. Jak wykorzystujemy zebrane informacje</h2>
                    <h3 className="text-xl font-semibold mb-2">2.1. Przetwarzanie treści witryny</h3>
                    <p className="mb-4">
                        Zebrane pytania i odpowiedzi z quizów są przesyłane do API OpenAI za pomocą naszego firmowego klucza API w celu uzyskania sugerowanych odpowiedzi. Odpowiedzi te są następnie wykorzystywane do zaznaczania prawidłowych odpowiedzi w quizach użytkownika.
                    </p>
                    <h3 className="text-xl font-semibold mb-2">2.2. Przechowywanie danych</h3>
                    <p>
                        Dane logowania są przechowywane bezpiecznie i używane wyłącznie do uwierzytelniania użytkowników w rozszerzeniu. Klucz API używany do autoryzowania zapytań do API OpenAI jest przechowywany i zarządzany przez naszą firmę.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">3. Udostępnianie informacji</h2>
                    <p>
                        Nie sprzedajemy, nie wymieniamy ani nie przekazujemy osobom trzecim Twoich danych osobowych. Wyjątki mogą stanowić sytuacje, gdy jest to wymagane przez prawo, na przykład w odpowiedzi na wezwanie sądowe, dochodzenie lub podobny proces prawny, albo gdy uważamy, że ujawnienie jest niezbędne do ochrony naszych praw, bezpieczeństwa użytkowników, bądź do odpowiedzi na rządowe zapytania.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">4. Bezpieczeństwo danych</h2>
                    <p>
                        Chronimy Twoje dane przy użyciu odpowiednich środków technicznych i organizacyjnych, aby zapobiec nieautoryzowanemu dostępowi, ujawnieniu, zmianie lub zniszczeniu danych. Przechowywanie danych logowania oraz klucza API odbywa się zgodnie z najwyższymi standardami bezpieczeństwa.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">5. Prawa użytkownika</h2>
                    <ul className="list-disc list-inside mb-4 ml-4">
                        <li><span className="font-bold">Dostęp do swoich danych</span>: Możesz poprosić o kopię danych, które przechowujemy na Twój temat.</li>
                        <li><span className="font-bold">Korekta danych</span>: Możesz zażądać korekty danych, które są niekompletne lub nieprawidłowe.</li>
                        <li><span className="font-bold">Usunięcie danych</span>: Możesz zażądać usunięcia swoich danych osobowych.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">6. Zmiany w Polityce Prywatności</h2>
                    <p>
                        Możemy aktualizować naszą Politykę Prywatności od czasu do czasu. O wszelkich zmianach będziemy informować, publikując nową wersję Polityki Prywatności na tej stronie. Zachęcamy do regularnego przeglądania niniejszej Polityki, aby być na bieżąco z naszymi działaniami ochrony prywatności.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">7. Kontakt</h2>
                    <p>
                        Jeśli masz jakiekolwiek pytania dotyczące naszej Polityki Prywatności, prosimy o kontakt:
                    </p>
                    <p className="font-bold">Dominik Hardek</p>
                    <p>E-mail: <a href="mailto:dominik.hardek@gmail.com" className="underline">support@quizonator.com</a></p>
                </section>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
