import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import CallToAction from './Components/CallToAction';
import Benefits from './Components/Benefits';
import Testimonials from './Components/Testimonials';
import FAQ from './Components/FAQ';
import Footer from './Components/Footer';
import Comparison from './Components/Comparison';
import About from './Components/About';
import QuzionatorInfo from './Components/QuzionatorInfo';
import LanguageSwitcher from './Components/LanguageSwitcher';
import Register from './Components/Register';
import Documentation from './Components/Documentation';
import PrivacyPolicy from './Components/PrivacyPolicy';
import MoreInfo from './Components/MoreInfo';
import './i18n';
import ResetPassword from './Components/ResetPassword';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/polityka-prywatnosci" element={<PrivacyPolicy />} />
          <Route path="/" element={
            <>
              <CallToAction />
              <Benefits />
              <QuzionatorInfo />
              <MoreInfo/>
              <Comparison />
              <Testimonials />
              <About />
              <FAQ />
              <Footer />
            </>
          } />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;